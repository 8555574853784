.sv_main, .sv_main .sv_custom_header, .sv_main .sv_body {
	background-color: transparent;
  font-family: Raleway,sans-serif;
}

.sv_main .sv_container {
  color: #fff;
}

.sv_main .sv_container .sv_header {
	padding: 0;
  color: #fff;
}

.sv_main .sv_container .sv_body {
	padding: 0;
	border-top: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
	padding: 0;
}

.sv_main .sv_p_root > .sv_row:nth-child(odd) {
	background-color: transparent;
	border-bottom: none;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
	font-weight: normal;
  font-size: 1.5em;
}

.sv_q_radiogroup {
	padding: 8px 16px;
  margin: 8px 0;
	border: 1px solid #999;
}

.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
	margin: 32px 0 16px;
}

.sv_main input[type="button"], .sv_main button {
	color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 12px 16px;
  font-size: 16px;
  min-width: 340px;
  align-self: center;
  min-height: 48px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #009688;
}

.sv_main input[type="button"], .sv_main button {
  background-color: #00916e !important;
}