a {
	color: #00916e;
}

input, .card-element {
	background-color: #fff !important;
}

.MuiSnackbarContent-root {
	background-color: #00916e !important;
}

/* account */
.signup__input-group {}
.signup__input-group .MuiOutlinedInput-root {
	border-radius: 0;
}

/* categories */