#root{
  height: 100%;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
}

html, body {
  background-color: #141414 !important;
  height: 100%;
  overflow: auto !important;
}

img {
	width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a, a:hover {
	text-decoration: none;
}

#back-to-top-anchor {
  display: none;
}

/*.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}*/

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  border-radius: 4px;
}

.card-element {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 6.5px 14px;
  margin: 0;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
}

/*.FormRow:first-child {
  border-top: none;
}*/

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #c4f0ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}

.ErrorMessage {
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.chart-svg {
	display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  width: 400px;
  text-align: center;
}

.circle-percentage {
	position: absolute;
  margin: 0;
  color: #369848;
  font-size: 48px;
  font-weight: 500;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.chart-circle {
	display: inline-block;
  position: relative;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.underlay {
  stroke-width: 5;
  fill: transparent;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #d5e8f7;
}

#circle-graph-1 {
  stroke: url(#gradient-1);
  stroke-width: 5;
  fill: transparent;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.content-placeholder {
  margin: 32px;
}

.content-placeholder-container {
  display: flex;
}

.content-placeholder-title {
  background-color: #353535;
  display: block;
  height: 2em;
  margin-bottom: 3em;
  overflow: hidden;
  position: relative;
  width: 25%;
}

.content-placeholder-item {
  background-color: #353535;
  display: block;
  width: 17rem;
  height: 10rem;
  margin-left: 1em;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.content-placeholder-item:first-of-type {
  margin-left: 0;
}

.content-placeholder-background {
  animation: content-placeholder-animation 1s linear infinite;
  background: -moz-linear-gradient(left, rgba(15, 15, 15, 0) 0%, rgba(88, 88, 88, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(15, 15, 15, 0) 0%, rgba(88, 88, 88, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(15, 15, 15, 0) 0%, rgba(88, 88, 88, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#000f0f0f", endColorstr="#00ffffff",GradientType=1 );
  display: block;
  height: inherit;
  left: 0;
  position: absolute;
  top: 0;
  width: 70%;
  will-change: transform;
}

@keyframes content-placeholder-animation {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(300%);
  }
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.MuiFormHelperText-root {
  color: #ff0000 !important;
}

#zmmtg-root {
	display: none;
	background-color: #141414 !important;
}

#zmmtg-root #content_container,
#zmmtg-root #content {
	background-color: #141414 !important;	
}

#zmmtg-root h1 {
	color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

#zmmtg-root #inputpasscode {
	margin: 16px 0;
  padding: 16px 6px;
  width: 100%;
  border: 0;
  outline: none;
  font-family: "Raleway", sans-serif;
}

#zmmtg-root .form-group {
	text-align: center;
}

.StripeElement {
	padding: 12px;
	background-color: #fff;
}