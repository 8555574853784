.development__container {
	padding: 0 32px;
}

.development__container-title {
	padding-bottom: 56px;
}

.development__container-section {
	padding-bottom: 32px;
}

.development__container-section-title {
	padding-bottom: 12px;
}