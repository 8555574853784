table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

thead th {
	border-bottom: 2px solid #999;
}

tbody th, tbody td {
	padding: 8px 0;
	border-bottom: 1px solid #d0cfcf;
}

tbody tr:last-child th, tbody tr:last-child td {
	border: none;
}