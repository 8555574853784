@media print {
	html, body, .body-root {
		background-color: #fff !important;
	}
	header, footer, .footerMenu, .copyright, button, .centificate h5 {
		display: none !important;
	}
	.centificate {
		width: 100% !important;
	}
	@page {
  	size: A4 !important;
	}
}