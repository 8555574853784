.button {
  padding: 12px 24px;
  background-color: #00916e;
  color: #fff,
}

.button:hover {
  background-color: #00916e;
}

.movie-strip {
  margin-bottom: 32px;
}

.movie-strip__header {
  padding: 0 32px;
}

.movie-strip__container {
	position: relative;
	overflow: hidden;
  margin-right: 32px;
  margin-left: 32px;
  width: 100%;
}

.movie-strip__container-inner {
	display: flex;
	overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  margin-left: -1rem;
  padding: 24px 0;
}

.movie-strip__container-inner::-webkit-scrollbar {
	display: none;
}

.movie-strip__container-inner .CarouselItem {
	scroll-snap-align: center;
}

.HorizontalCenter {
  position: relative;
	display: flex;
	/*justify-content: center;*/
  margin-left: 0;
  margin-right: 0;

  /*width: 100%;*/
}

.CarouselItem {
	flex: 0 0 auto;
  margin-left: 1rem;
  z-index: 1;
  transform: scale(1);
  transform-origin: center center;
  transition-property: all;
  transition-duration: .45s;
  cursor:	pointer;
}

.CarouselItem.active, .CarouselItem:hover {
	z-index: 9;
	transform: scale(1.25);
}

.movie-strip__container-inner div:last-of-type {
	transform-origin: center right;
}

.movie-strip__container-inner div:first-of-type {
  transform-origin: center left;
}

.CarouserItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 272px;
  height: 155px;
  overflow: hidden;
}

.CarouserItem img {
	border-radius: 5px;
}


.CarouselButton {
	position: absolute;
  cursor: pointer;
  top: 0;
  z-index: 1;
  transition: transform 0.1s ease-in-out;
  /*background: rgba(0,0,0,0.5);*/
  border: none;
  padding: 0.5rem;
}

.LeftCarouselButton {
	z-index: 20;
	left: 0;
  transform: translate(-30%, 0%);
  visibility: hidden;
  display: flex;
  align-items: center;
  height: 224px;
}

.HorizontalCenter:hover .hasItemsOnLeft {
	visibility: visible;
}

/*.movie-strip__container:hover .LeftCarouselButton {
	transform: translate(0%, -50%);
}*/

.RightCarouselButton {
	z-index: 20;
  right: 0;
  transform: translate(30%, 0%);
  visibility: hidden;
  display: flex;
  align-items: center;
  height: 224px;
}

.HorizontalCenter:hover .hasItemsOnRight {
	visibility: visible;
}

/*.movie-strip__container:hover .RightCarouselButton {
	transform: translate(0%, -50%);
}*/

.tile {
  position: relative;
  display: inline-block;
  margin: 4px 6px;
  width: 250px;
  height: 139px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 4px;
  webkit-transition: 450ms all;
  transition: 450ms all;
  webkit-transform-origin: center center;
  transform-origin: center center;
  z-zndex: 1;
}

.tile.extra {
  background: transparent;
  height: 265px;
  border: 0;
}

.tile:nth-child(1) {
  webkit-transform-origin: center left;
  transform-origin: center left;
}

.tile:nth-last-child(1) {
  webkit-transform-origin: center right;
  transform-origin: center right;
}

.tile:hover {
  webkit-transform: scale(1.4);
  transform: scale(1.4);
  z-index: 999;
}

.selected-class {
  padding: 16px;
  background-color: #222;
  min-height: 400px;
}

.selected-class .tile {
  color: #fff;
}
        
.selected-class .tile:hover {
  transform: none;
}

.selected-class.inactive {
  display: none;
}

.selected-class.active {
  display: block;
}

.tag {
  margin-right: 8px;
}

.inline {
  margin: 0;
}

.inline dd {
  display: inline;
  margin: 0;
}

.inline dd::after {
  display: block;
  content: "";
}

.inline dt {
  display: inline-block;
  min-width: 150px;
}

.MuiTabs-flexContainer {
  border-bottom: 2px solid #404040;
}

.similar {
  display: flex;
  justify-content: space-between;
}

.close {
  position: absolute;
  right: 16px;
  z-index: 99;
  cursor: pointer;
}

.course-checked {
  margin: 0 12px !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.movie-strip ul {
	list-style-type: disc;
	padding-left: 16px;
}

.similar {
  display: flex;
  justify-content: flex-start;
  margin: 0 -16px;
  width: 100%;
}
.similar-card {
  margin: 0 16px;
  width: 20%;
  background: transparent !important;
  border: 0 !important;
}
.similar-media {
  background-color: #222;
  background-size: cover;
  height: 0;
  padding-top: 56.25%;
  border-radius: 5px;
}